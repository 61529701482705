import { appQueryClient } from '../../provider'

export const analysisCacheKeys = {
  upload_revenue: () => ['upload_analysis_revenue'] as const,
  all: queryParams => ['analysis', queryParams] as const
}

export const analysisInvalidations = {
  invalidateAll: () => {
    appQueryClient.invalidateQueries({
      queryKey: analysisCacheKeys.all({})
    })
  }
}
