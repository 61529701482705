import { analysisService } from '@libs/data-access/queries'
import { notifications } from '@libs/ui/ds'
import i18n from '@libs/utils/translations'
import { computeValidEndDate } from '@spa/scenes/Plannings/utils'
import { copyDateOnly } from '@spa/utils/date'
import { put, select } from 'redux-saga/effects'
import { createShift } from 'snap-redux/modules/shifts'
import setDateDefaults from 'snap-redux/modules/shifts/utils/breaks/setDateDefaults'

import { shiftsSelectors, weeklyschedulesSelectors } from '../../../selectors'
import type { PayloadActionWithoutType } from '../../types/types'

type Params = PayloadActionWithoutType<{
  shiftId: number
  to: {
    weeklyscheduleId: number
    contractId: number
    date: string
  }
}>

function* copyShift(action: Params) {
  const {
    payload: { shiftId, to }
  } = action

  const shift = yield select(state =>
    shiftsSelectors.getCachedById(state, shiftId)
  )

  const shiftWeeklyschedule = yield select(state =>
    weeklyschedulesSelectors.getCachedById(state, shift.weeklyschedule_id)
  )
  const toWeeklyschedule = yield select(state =>
    weeklyschedulesSelectors.getCachedById(state, to.weeklyscheduleId)
  )
  const sameTeam = shiftWeeklyschedule.team_id === toWeeklyschedule.team_id

  const edited = {
    start: copyDateOnly(shift.starts_at, to.date),
    end: copyDateOnly(shift.ends_at, computeValidEndDate(shift, to.date)),
    labelId: sameTeam ? shift.label_id : null,
    note: shift.note,
    meals: shift.shift_meals.filter(
      // Prevent duplication of real meals
      item => !!item.planned_meal_type
    ),
    breaks: shift.shift_breaks
      .filter(
        // Prevent duplication of real breaks
        item => item.planned_duration !== 0
      )
      .map(item => {
        const newItem = setDateDefaults(item, shift.starts_at, to.date)
        // Adapt date for breaks
        return {
          ...item,
          ...newItem
        }
      })
  }

  yield put(
    createShift(
      {
        starts_at: edited.start.format(),
        ends_at: edited.end.format(),
        label_id: edited.labelId,
        shift_meals_attributes: edited.meals,
        shift_breaks_attributes: edited.breaks,
        weeklyschedule_id: to.weeklyscheduleId,
        user_contract_id: to.contractId,
        note: edited.note,
        notify_user: true
      },
      () => analysisService.invalidateFetch(),
      () => {
        notifications.error({
          message: i18n.t<any>(
            'common.notifications:planning.shifts.copy.error'
          )
        })
      }
    )
  )
}

export default copyShift
