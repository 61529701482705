import { analysisInvalidations } from './analysis.cacheKeys'
import { useFetchAnalysis } from './hooks/useFetchAnalysis'
import { useUpdateAnalysisRevenue } from './hooks/useUpdateAnalysisRevenue'

class AnalysisService {
  useFetch = useFetchAnalysis

  invalidateFetch = analysisInvalidations.invalidateAll

  updateRevenue = useUpdateAnalysisRevenue
}

export const analysisService = new AnalysisService()
