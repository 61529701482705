import type { AnalysisSchema } from '@libs/data-access/entities'
import { appQueryClient } from '@libs/data-access/queries'
import { useMutation } from '@tanstack/react-query'

import type { ApiErrorResponse, UseAppMutationOptions } from '../../../types'
import {
  type UpdateAnalysisRevenueOptions,
  updateAnalysisRevenue
} from '../analysis.api'
import { analysisCacheKeys } from '../analysis.cacheKeys'

export const useUpdateAnalysisRevenue = (
  mutationOptions?: UseAppMutationOptions<
    AnalysisSchema,
    ApiErrorResponse,
    UpdateAnalysisRevenueOptions
  >
) => {
  return useMutation<
    AnalysisSchema,
    ApiErrorResponse,
    UpdateAnalysisRevenueOptions
  >({
    ...mutationOptions,
    mutationKey: analysisCacheKeys.upload_revenue(),
    mutationFn: (opts: UpdateAnalysisRevenueOptions) =>
      updateAnalysisRevenue(opts),
    onSuccess: (...args) => {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      mutationOptions?.onSuccess(...args)
      appQueryClient.invalidateQueries({
        queryKey: analysisCacheKeys.all({})
      })
    }
  })
}
