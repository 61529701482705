import type { ShiftSchema } from '@libs/data-access/entities'
import { analysisService } from '@libs/data-access/queries'
import { notifications } from '@libs/ui/ds'
import i18n from '@libs/utils/translations'
import { computeValidEndDate } from '@spa/scenes/Plannings/utils'
import { copyDateOnly } from '@spa/utils/date'
import { put, select } from 'redux-saga/effects'
import { updateShift } from 'snap-redux/modules/shifts'
import setDateDefaults from 'snap-redux/modules/shifts/utils/breaks/setDateDefaults'

import { shiftsSelectors } from '../../../selectors'
import type { PayloadActionWithoutType } from '../../types/types'

type Params = PayloadActionWithoutType<{
  shiftId: number
  to: {
    weeklyscheduleId: number
    contractId: number
    date: string
  }
}>

function* moveShift(action: Params): any {
  const {
    payload: { shiftId, to }
  } = action

  const { id: _, ...shift }: ShiftSchema = yield select(state =>
    shiftsSelectors.getCachedById(state, shiftId)
  )

  const sameWeeklySchedule = to.weeklyscheduleId === shift.weeklyschedule_id

  const edited = {
    start: copyDateOnly(shift.starts_at, to.date),
    end: copyDateOnly(shift.ends_at, computeValidEndDate(shift, to.date)),
    labelId: sameWeeklySchedule ? shift.label_id : null,
    meals: shift.shift_meals,
    breaks: shift.shift_breaks.map(item => {
      // We only copy planned breaks
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      if (item.planned_starts_at) {
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        return setDateDefaults(item, shift.starts_at, to.date)
      }

      return {
        ...item,
        // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
        real_duration: item.real_duration || item.planned_duration
      }
    })
  }

  yield put(
    updateShift(
      shiftId,
      {
        starts_at: edited.start.format(),
        ends_at: edited.end.format(),
        label_id: edited.labelId,
        shift_meals_attributes: edited.meals,
        shift_breaks_attributes: edited.breaks,
        weeklyschedule_id: to.weeklyscheduleId,
        user_contract_id: to.contractId,
        notify_user: true
      },
      () => analysisService.invalidateFetch(),
      () => {
        notifications.error({
          message: i18n.t<any>(
            'common.notifications:planning.shifts.move.error'
          )
        })
      }
    )
  )
}

export default moveShift
