import type {
  FetchMembershipContractsQueryParams,
  FetchMembershipNamesQueryParams,
  FetchMembershipsQueryParams,
  FetchSearchedMembershipsQueryParams,
  MembershipContractTimeline,
  MembershipNameOnly,
  MembershipSchema
} from '@libs/data-access/entities'
import { httpServices, requestOptions } from '@libs/data-access/queries'

import type { PaginatedQueryResponse, QueryFnParams } from '../../../types'

export type ParamsType = {
  id: number
  membershipQueryParams?: {
    includes: MembershipQuery
  }
}

export const membershipIncludes = [
  'employee_id_mappings',
  'client_partners',
  'modulation',
  'work_permits',
  'line_manager',
  'subordinates_count'
] as const

export type MembershipQuery = (typeof membershipIncludes)[number][]

export type FetchMembershipsOptions = QueryFnParams<{
  queryParams: FetchMembershipsQueryParams
}>

/**
 *
 */
export const fetchMemberships = async (opts: FetchMembershipsOptions) => {
  const response = await httpServices.core.get<MembershipSchema[]>(
    `/accounts/${httpServices.authInfo.accountId}/memberships`,
    {
      params: opts?.queryParams
    }
  )
  return response.data
}

export type FetchMembershipContractsOptions =
  QueryFnParams<FetchMembershipContractsQueryParams>

/**
 *
 */
export const fetchMembershipContracts = async (
  opts: FetchMembershipContractsOptions
) => {
  const response = await httpServices.core.get<
    PaginatedQueryResponse<MembershipContractTimeline, 'contracts'>
  >(
    `/employee_management/memberships/${opts?.pathParams.membership_id}/contracts`,
    { params: opts?.queryParams }
  )
  return response.data
}

export type FetchMembershipOptions = QueryFnParams<{
  pathParams: {
    membership_id: number
  }
  queryParams: {
    includes: MembershipQuery
  }
}>

export const fetchMembershipById = async (opts: FetchMembershipOptions) => {
  const response = await httpServices.core.get<MembershipSchema>(
    `/memberships/${opts.pathParams.membership_id}`,
    {
      params: opts.queryParams
    }
  )

  return response.data
}

export type FetchMembershipNamesOptions =
  QueryFnParams<FetchMembershipNamesQueryParams>

export const fetchMembershipNames = async (
  opts: FetchMembershipNamesOptions
) => {
  const response = await httpServices.core.get<MembershipNameOnly[]>(
    `/memberships/names`,
    {
      params: opts?.queryParams
    }
  )
  return response.data
}

export type FetchSearchedMembershipsOptions = QueryFnParams<{
  queryParams: FetchSearchedMembershipsQueryParams
}>

export const fetchSearchedMemberships = async (
  opts: FetchSearchedMembershipsOptions
) => {
  const response = await httpServices.core.get<
    PaginatedQueryResponse<MembershipSchema, 'memberships'>
  >(`/accounts/${httpServices.authInfo.accountId}/memberships`, {
    params: {
      ...opts?.queryParams,
      active: true,
      filtered: true,
      per_page: requestOptions.PAGINATION_DEFAULT_PER_PAGE,
      page: requestOptions.PAGINATION_DEFAULT_PAGE
    }
  })
  return response.data
}

export type UpdateMembershipPayloadOptions = QueryFnParams<{
  pathParams: {
    membership_id: number
  }
  payload: Partial<MembershipSchema>
}>

export const updateMembership = async (
  opts: UpdateMembershipPayloadOptions
) => {
  const response = await httpServices.core.put<MembershipSchema>(
    `/memberships/${opts.pathParams.membership_id}`,
    opts?.payload
  )

  return response.data
}

export type DeleteMembershipPayloadOptions = QueryFnParams<{
  pathParams: {
    membership_id: number
  }
}>

export const deleteMembership = async (
  opts: DeleteMembershipPayloadOptions
) => {
  const response = await httpServices.core.delete(
    `/memberships/${opts.pathParams.membership_id}`
  )

  return response.data
}
