import type { UseAppInfiniteQueryOptions } from '@libs/data-access/queries'
import { useAppInfiniteQuery } from '@libs/data-access/queries'

import type {
  FetchSubordinateOptions,
  GetPaginatedSubordinates
} from '../membership.subordinate.api'
import { fetchSubordinates } from '../membership.subordinate.api'
import { membershipSubordinateCacheKeys } from '../membership.subordinate.cacheKeys'

export const useFetchSubordinates = (
  request: FetchSubordinateOptions,
  queryOptions?: UseAppInfiniteQueryOptions<GetPaginatedSubordinates>
) => {
  return useAppInfiniteQuery(
    membershipSubordinateCacheKeys.subordinates(request.pathParams),
    ({ pageParam }) => fetchSubordinates(request, Number(pageParam)),
    queryOptions
  )
}
