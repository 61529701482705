import type {
  AnalysisSchema,
  UpdateAnalysisRevenuePayload
} from '@libs/data-access/entities'

import { httpServices } from '../../apis/http/httpServices'
import type { QueryFnParams } from '../../types'

export type FetchAnalysisOptions = QueryFnParams<{
  queryParams?: {
    date: string
    location_id?: string
    team_id?: string
  }
}>

export const fetchAnalysis = async (opts?: FetchAnalysisOptions) => {
  const response = await httpServices.core.get<AnalysisSchema>(
    '/planning/weeklyschedules/analysis',
    {
      // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
      params: opts?.queryParams
    }
  )
  return response.data
}

export type UpdateAnalysisRevenueOptions = QueryFnParams<{
  payload: UpdateAnalysisRevenuePayload
  queryParams: {
    date: string
    location_id: string
    team_id?: string
  }
}>

export const updateAnalysisRevenue = async (
  opts?: UpdateAnalysisRevenueOptions
) => {
  const response = await httpServices.core.put<AnalysisSchema>(
    '/planning/weeklyschedules/analysis',
    opts?.payload,
    {
      params: opts?.queryParams
    }
  )
  return response.data
}
