import type {
  ContractRecordMap,
  PlannableOverlapExtended
} from '@libs/data-access/entities'
import { Alert, Flex, Text } from '@libs/ui/ds'
import { usePlanningFormTranslation } from '@libs/utils/translations'
import type { FC } from 'react'

import { ContractsMultiCheckbox } from './ContractsMultiCheckbox'

type Props = {
  contracts: ContractRecordMap
  plannableOverlapExtended: PlannableOverlapExtended
}

export const ShiftPlannedOverPeriod: FC<Props> = ({
  contracts,
  plannableOverlapExtended
}) => {
  const { t } = usePlanningFormTranslation()
  const isMultiContract =
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    plannableOverlapExtended.contracts_only_shift_overlap?.length > 1
  // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
  const isMultiShift = plannableOverlapExtended.total_shift_count > 1

  return (
    <Alert
      intent='semantic.red'
      title={
        isMultiShift
          ? t('restForm.shiftPlannedOverPeriodTitleMulti')
          : t('restForm.shiftPlannedOverPeriodTitle')
      }
      className='mb-2'
    >
      {isMultiContract && !isMultiShift && (
        <Text>{t('restForm.shiftPlannedOverPeriodWarning')}</Text>
      )}
      {isMultiContract && isMultiShift && (
        <Text>{t('restForm.shiftPlannedOverPeriodWarningMulti')}</Text>
      )}
      <Flex className='mt-1'>
        <ContractsMultiCheckbox
          contracts={contracts}
          plannableOverlapExtended={plannableOverlapExtended}
        />
      </Flex>
    </Alert>
  )
}
