import type { AnalysisSchema } from '@libs/data-access/entities'
import { useQuery } from '@tanstack/react-query'

import type { UseAppQueryOptions } from '../../../types'
import { type FetchAnalysisOptions, fetchAnalysis } from '../analysis.api'
import { analysisCacheKeys } from '../analysis.cacheKeys'

export const useFetchAnalysis = (
  request: FetchAnalysisOptions,
  queryOptions?: UseAppQueryOptions<AnalysisSchema>
) => {
  return useQuery({
    ...queryOptions,
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    queryKey: analysisCacheKeys.all(request?.queryParams),
    queryFn: () => fetchAnalysis(request)
  })
}
