import type {
  ContractRecordMap,
  PlannableOverlapExtended
} from '@libs/data-access/entities'
import { Badge, Checkbox, Flex, Text } from '@libs/ui/ds'
import { usePlanningFormTranslation } from '@libs/utils/translations'
import { membershipsSelectors } from '@spa/scenes/Plannings/redux/selectors'
import { keyBy } from 'lodash-es'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type Props = {
  contracts: ContractRecordMap
  plannableOverlapExtended: PlannableOverlapExtended
}

export const ContractsMultiCheckbox: FC<Props> = ({
  contracts,
  plannableOverlapExtended
}) => {
  const { t } = usePlanningFormTranslation()

  const contractsOnlyShiftOverlap =
    plannableOverlapExtended.contracts_only_shift_overlap?.map(contract =>
      contract.user_contract_id.toString()
    ) || []

  useEffect(() => {
    if (plannableOverlapExtended.contracts_only_shift_overlap?.length) {
      setValue('contracts_ids_shift_overlap', contractsOnlyShiftOverlap)
    }
  }, [plannableOverlapExtended])

  const { control, watch, setValue } = useFormContext()
  const contractsShifts =
    plannableOverlapExtended.contracts_only_shift_overlap || []
  const contractById = keyBy(contracts.toArray(), 'id')
  const memberships = membershipsSelectors.useGetCachedMap()

  const contractItems = contractsShifts.map(item => {
    const membershipId = contractById[item.user_contract_id]?.membership_id
    const membership = memberships[membershipId]

    return {
      value: String(item.user_contract_id),
      label: (
        <Flex row className='text-semantic-red-500'>
          {t('common.app:models.fullname', {
            value: {
              firstname: membership?.firstname,
              lastname: membership?.lastname
            }
          })}
          <Badge
            size='s'
            label={item?.shift_count.toString()}
            color='semantic.red'
            className='ml-2'
          />
        </Flex>
      )
    }
  })

  const selectedContractIds = contractItems.map(contract => contract.value)

  const countContractsWithOverlappedShifts =
    plannableOverlapExtended?.contracts_only_shift_overlap?.length || 0

  const isAllSelected =
    watch('contracts_ids_shift_overlap').length ===
    contractsOnlyShiftOverlap.length

  return (
    <>
      {countContractsWithOverlappedShifts > 1 && (
        <Checkbox
          position='right'
          label={
            <Text className='text-semantic-red-500'>
              {t('component.staffSelector.selectAll')}
            </Text>
          }
          checked={isAllSelected}
          onChange={() =>
            isAllSelected
              ? setValue('contracts_ids_shift_overlap', [])
              : setValue('contracts_ids_shift_overlap', selectedContractIds)
          }
          size='s'
        />
      )}
      <Controller
        name='contracts_ids_shift_overlap'
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <Checkbox.Group
            size='s'
            name={name}
            position='top-stacked'
            value={value}
            className='flex w-full !items-start'
            onChange={onChange}
          >
            {contractItems.map(item => (
              <Checkbox
                position='right'
                key={item.value}
                label={item.label}
                value={item.value}
                checked={value.includes(item.value)}
                size='s'
              />
            ))}
          </Checkbox.Group>
        )}
      />
    </>
  )
}
