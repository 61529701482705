import type {
  PaginatedQueryRequest,
  PaginatedQueryResponse,
  QueryFnParams
} from '@libs/data-access/queries'
import { httpServices } from '@libs/data-access/queries'

import type { SubordinateSchema } from './types/membership.subordinate.schema'

export type FetchSubordinateOptions = QueryFnParams<{
  pathParams: {
    membership_id: number
  }
  queryParams: PaginatedQueryRequest
}>

export type GetPaginatedSubordinates = PaginatedQueryResponse<
  SubordinateSchema,
  'pages'
> & {
  pageParams: number[]
}

export const fetchSubordinates = async (
  opts: FetchSubordinateOptions,
  pageParam: number
): Promise<GetPaginatedSubordinates> => {
  const response = await httpServices.core.get<GetPaginatedSubordinates>(
    `/employee_management/memberships/${opts.pathParams.membership_id}/subordinates`,
    { params: { ...opts?.queryParams, page: pageParam } }
  )

  return response.data
}
